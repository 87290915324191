<template>
  <div class="news">
    <headerother titleurl="homepage" ref="header"></headerother>
    <div class="news-bgc">
      <el-tabs v-model="activeName">
        <el-tab-pane label="快报" name="first">
          <div class="_3oESJEbA6EDqNUcNu8namk">
            <ul id="list-646" style="display: block;min-height:430px;">
              <li class="_22Oix1ELZz1MDoP6GMIm0F" v-for="(item, i) in newslist" :key="i" >
                 <router-link  :to="'/news/newsdetail?id=' + item.id" target="_blank" class="news_link">
                         
                         
                  <div class="_1gK3wUGCGeMVxu-32dF4Im">
                    <div class="_3VbmUI3ZuVpoS7-YNYXcJo c7DNzkcG7rbA6gFkZPklM"><img
                        :src="item.titleThumbPicPath"
                        class="_1ly13xh54Zg8BuvNqyKAZW" alt="预览图" clstag="indx|news#01_01a"></div>
                  </div>
                  <div class="_3A06q0i5gFYApDhhEM4i78">
                    <div class="_27CzaCw-p0CS-ah_PEIDAm">{{item.title}}</div>
                    <div class="_3HHIrt8tpulw42bJ1C_C88">
                    </div>
                  </div>
                 </router-link>
                </li>
            </ul>
          </div>
        </el-tab-pane>
        <!-- <el-tab-pane label="通知公告" name="second">通知公告</el-tab-pane> -->
      </el-tabs>
      <el-pagination @size-change="handleSizeChange" background @current-change="handleCurrentChange"
            :current-page="pageIndex" :page-sizes="[12, 24, 36]" :page-size="pageSize" layout="total, prev, pager, next"
            :total="totalCount">
          </el-pagination>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import { sysnewsgetnewlist } from '@/api/system';
import headerother from '../../components/headerother';
import Footer from '../../components/footer';
import Cookies from 'js-cookie';
export default {
  name: 'homepage',
  components: {
    headerother,
    Footer,
  },
  data() {
    return {
      activeName: 'first',
      newslist: [],
      pageIndex: 1,
      pageSize: 12,
      totalCount: 0,
    };
  },
  methods: {
    handleCurrentChange(item) {
      this.pageIndex = item;
      this.onsysnewsgetnewlist();
    },
    handleSizeChange(item) {
      this.pageSize = item;
      this.onsysnewsgetnewlist();
    },
    onsysnewsgetnewlist() {
      var that = this;
      sysnewsgetnewlist({
        categoryId: null,
        sortRule: 0,
        keyword: '',
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
      }).then((res) => {
        if (res.data.code === 1) {
          that.newslist = res.data.result.list;
          that.totalCount = res.data.result.totalCount;
        }
      });
    },
  },
  created() {
    this.onsysnewsgetnewlist();
  },
};
</script>
<style scoped>
.news .news-bgc {
  /* width: 100%; */
  margin: 0 auto;
  background: #fff;
  width: 1140px;
  margin-top: 30px;
  min-height: 560px;
  padding: 10px 30px;
}
._2F8zhWYDQZ0r-aOB6zIJB * {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
._22Oix1ELZz1MDoP6GMIm0F {
  padding: 15px 0;
  border-bottom: 1px solid #f0f0f0;
  position: relative;
}
._1gK3wUGCGeMVxu-32dF4Im {
  width: 220px;
  min-width: 222px;
  max-width: 230px;
  height: 150px;
  margin-right: 20px;
  overflow: hidden;
  display: inline-block;
  position: relative;
}
._3VbmUI3ZuVpoS7-YNYXcJo {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  background: #eee;
  -webkit-transition: background 0.2s linear;
  -o-transition: background 0.2s linear;
  -moz-transition: background 0.2s linear;
  transition: background 0.2s linear;
}
.c7DNzkcG7rbA6gFkZPklM {
  background: transparent;
}
._3A06q0i5gFYApDhhEM4i78 {
  position: relative;
  vertical-align: top;
  display: inline-block;
  width: 480px;
  height: 100%;
}
._27CzaCw-p0CS-ah_PEIDAm {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 15px;
}
._3HHIrt8tpulw42bJ1C_C88 {
  font-size: 14px;
  line-height: 22px;
  height: 44px;
  color: #999;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
.news-bgc >>> .el-tabs__item {
  font-size: 16px;
  font-weight: 800;
}
.news-bgc >>>.el-pagination{padding:30px 0;text-align: center;}
</style>